import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

export interface NotFoundPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = props => {
  return (
    <Layout transparentHeaderOnTop={false}>
      <SEO title="Página no encontrada"></SEO>
      <div
        className="container px-4 text-center"
        css={{
          marginTop: '10em',
        }}
      >
        <h2>La página solicitada no se ha encontrado</h2>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
